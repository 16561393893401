import { z } from "zod";
import { CHANGE_SCHEMA, FREQUENT_CHANGE_SCHEMA } from "./snapshot";

export const UPDATE_REQUEST_SCHEMA = z.object({
  type: z.literal("update"),
  changes: z.array(CHANGE_SCHEMA),
});
export const FREQUENT_UPDATE_REQUEST_SCHEMA = z.object({
  type: z.literal("frequent_update"),
  change: FREQUENT_CHANGE_SCHEMA,
});
export const JOIN_REQUEST_SCHEMA = z.object({
  type: z.literal("join"),
});
export const SYNC_REQUEST_SCHEMA = z.object({
  type: z.literal("sync"),
});
export const REQUEST_SCHEMA = z.union([
  UPDATE_REQUEST_SCHEMA,
  FREQUENT_UPDATE_REQUEST_SCHEMA,
  JOIN_REQUEST_SCHEMA,
  SYNC_REQUEST_SCHEMA,
]);
export type UpdateRequest = z.infer<typeof UPDATE_REQUEST_SCHEMA>;
export type FrequentUpdateRequest = z.infer<typeof FREQUENT_UPDATE_REQUEST_SCHEMA>;
export type JoinRequest = z.infer<typeof JOIN_REQUEST_SCHEMA>;
export type SyncRequest = z.infer<typeof SYNC_REQUEST_SCHEMA>;
export type Request = z.infer<typeof REQUEST_SCHEMA>;

export const UPDATE_RESPONSE_SCHEMA = z.object({
  type: z.literal("update"),
  state: z.object({}).passthrough(),
  changeIds: z.array(z.string()),
});
export const FREQUENT_UPDATE_RESPONSE_SCHEMA = z.object({
  type: z.literal("frequent_update"),
  state: z.object({}).passthrough(),
});
export const JOIN_RESPONSE_SCHEMA = z.object({
  type: z.literal("join"),
  clientId: z.string(),
});
export const SYNC_RESPONSE_SCHEMA = z.object({
  type: z.literal("sync"),
  state: z.object({}).passthrough(),
});
export const RESPONSE_SCHEMA = z.union([
  UPDATE_RESPONSE_SCHEMA,
  FREQUENT_UPDATE_RESPONSE_SCHEMA,
  JOIN_RESPONSE_SCHEMA,
  SYNC_RESPONSE_SCHEMA,
]);
export type UpdateResponse = z.infer<typeof UPDATE_RESPONSE_SCHEMA>;
export type FrequentUpdateResponse = z.infer<typeof FREQUENT_UPDATE_RESPONSE_SCHEMA>;
export type JoinResponse = z.infer<typeof JOIN_RESPONSE_SCHEMA>;
export type SyncResponse = z.infer<typeof SYNC_RESPONSE_SCHEMA>;
export type Response = z.infer<typeof RESPONSE_SCHEMA>;
