import * as visGm from "@vis.gl/react-google-maps";
import DeckGL from "@deck.gl/react";
import useWebSocket from "react-use-websocket";
import { BSON } from "bson";
import { Position } from "geojson";
import invariant from "tiny-invariant";
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";

import CursorLayer from "./cursorLayer";
import { Client, State, Change, FrequentChange, UpdateRequest, RESPONSE_SCHEMA, JoinRequest, SyncRequest, FrequentUpdateRequest, idLib } from "@larki/mp";
import { positionLib } from "./lib/position";

import { useThrottle } from "./hooks/useThrottle";
import { vertexLib } from "./lib/vertex";
import ShapeLayer, { ShapeLayerPickingInfoT } from "./shapeLayer";
import { WorldState, WorldStateT } from "./worldState";
import DeleteVertexLayer, { DeleteVertexLayerPickingInfoT } from "./deleteVertexLayer";
import AddVertexLayer, { AddVertexLayerPickingInfoT } from "./addVertexLayer";
import {
  BooleanVertexFeature,
  PathVertexFeature,
  PolygonVertexFeature,
  RectangleVertexFeature,
  SHAPE_TYPE,
  TentativeShapeType,
} from "./lib/shape";
import { rectangleLib } from "./lib/rectangle";
import { useKeyPress } from "./hooks/useKeyPress";
import { WorldStateInterpolator } from "./interpolator";

// import './RealtimeMap.scss'

const INITIAL_MAP_OPTIONS = {
  CENTER: [144.9623, -37.8124] satisfies [number, number], // https://geojson.io/#map=18/-37.8124/144.9623
  ZOOM: 18,
};
const GM_MAP_OPTIONS: visGm.MapProps = {
  style: { width: "100vw", height: "100vh" },
  defaultCenter: {
    lng: INITIAL_MAP_OPTIONS.CENTER[0],
    lat: INITIAL_MAP_OPTIONS.CENTER[1],
  },
  defaultZoom: INITIAL_MAP_OPTIONS.ZOOM,
  disableDefaultUI: true,
  isFractionalZoomEnabled: true,
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ],
};
const MAP_ID = "map";
const MIN_DISTANCE_TO_DELETE_VERTEX_PIXELS = 500;
const MIN_DISTANCE_TO_CREATE_VERTEX_PIXELS = 500;

type Mode = "idle" | "creating_polygon" | "creating_rectangle" | "creating_path" | "creating_boolean";
type CreatingMode = Exclude<Mode, "idle">;
type ModeAction =
  | "start_creating_polygon"
  | "start_creating_rectangle"
  | "start_creating_path"
  | "start_creating_boolean"
  | "stop_creating";
const modeReducer = (mode: Mode, action: ModeAction): Mode => {
  if (action === "start_creating_polygon") {
    return "creating_polygon";
  } else if (action === "start_creating_rectangle") {
    return "creating_rectangle";
  } else if (action === "start_creating_path") {
    return "creating_path";
  } else if (action === "start_creating_boolean") {
    return "creating_boolean";
  } else if (action === "stop_creating") {
    return "idle";
  }
  return mode;
};
const isModeCreating = (mode: Mode): mode is CreatingMode => mode !== "idle";

class ModeNotCreatingError extends Error {}
const creatingModeToTentativeShapeType = (mode: CreatingMode): TentativeShapeType => {
  switch (mode) {
    case "creating_polygon":
      return "polygon";
    case "creating_rectangle":
      return "rectangle";
    case "creating_path":
      return "path";
    case "creating_boolean":
      return "boolean";
    default:
      throw new ModeNotCreatingError();
  }
};

type WebsocketStatus = "idle" | "initializing" | "joining" | "syncing" | "synced";
const websocketStatusReducer = (
  state: WebsocketStatus,
  action: "connect" | "initialize" | "join" | "sync" | "disconnect"
): WebsocketStatus => {
  if (state === "idle" && action === "connect") {
    return "initializing";
  } else if (state === "initializing" && action === "initialize") {
    return "joining";
  } else if (state === "joining" && action === "join") {
    return "syncing";
  } else if (state === "syncing" && action === "sync") {
    return "synced";
  } else if (action === "disconnect") {
    return "idle";
  }
  return state;
};

type PickingInfoT = ShapeLayerPickingInfoT | DeleteVertexLayerPickingInfoT | AddVertexLayerPickingInfoT;

type MidpointVertex = PolygonVertexFeature | PathVertexFeature;
type BaseMidpointToCreateVertex<V = MidpointVertex> = {
  before: V;
  after: V;
  position: Position;
  xyDistance: number;
};

export type PolygonMidpointToCreateVertex = BaseMidpointToCreateVertex<PolygonVertexFeature> & {
  shapeType: "polygon";
};
export type PathMidpointToCreateVertex = BaseMidpointToCreateVertex<PathVertexFeature> & {
  shapeType: "path";
};
export type PolygonBooleanMidpointToCreateVertex = BaseMidpointToCreateVertex<BooleanVertexFeature<"polygon">> & {
  parentShapeType: "polygon";
  shapeType: "boolean";
};
export type RectangleBooleanMidpointToCreateVertex = BaseMidpointToCreateVertex<BooleanVertexFeature<"rectangle">> & {
  parentShapeType: "rectangle";
  shapeType: "boolean";
};
export type MidpointToCreateVertex =
  | PolygonMidpointToCreateVertex
  | PathMidpointToCreateVertex
  | PolygonBooleanMidpointToCreateVertex
  | RectangleBooleanMidpointToCreateVertex;

const changesLib = {
  polygon: {
    exterior: {
      add: (featureId: string, vertexId: string, prevId: string, position: Position, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "insert",
          path: ["polygons", featureId, "exterior", vertexId],
          property: { value: positionLib.toString(position), t },
          prevKey: prevId,
        },
      ],
      update: (featureId: string, vertexId: string, position: Position, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "set",
          path: ["polygons", featureId, "exterior", vertexId],
          property: { value: positionLib.toString(position), t },
        },
      ],
      delete: (featureId: string, vertexId: string, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "delete",
          path: ["polygons", featureId, "exterior", vertexId],
          property: { t },
        },
      ],
    },
    all: {
      delete: (featureId: string, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "delete",
          path: ["polygons", featureId],
          property: { t },
        },
      ],
    },
  },
  rectangle: {
    exterior: {
      update: (featureId: string, vertexId: string, position: Position, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "set",
          path: ["rectangles", featureId, "exterior", vertexId, "position"],
          property: { value: positionLib.toString(position), t },
        },
      ],
    },
  },
  path: {
    vertex: {
      add: (featureId: string, vertexId: string, prevId: string, position: Position, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "insert",
          path: ["paths", featureId, vertexId],
          property: { value: positionLib.toString(position), t },
          prevKey: prevId,
        },
      ],
      update: (featureId: string, vertexId: string, position: Position, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "set",
          path: ["paths", featureId, vertexId],
          property: { value: positionLib.toString(position), t },
        },
      ],
      delete: (featureId: string, vertexId: string, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "delete",
          path: ["paths", featureId, vertexId],
          property: { t },
        },
      ],
    },
    all: {
      delete: (featureId: string, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "delete",
          path: ["paths", featureId],
          property: { t },
        },
      ],
    },
  },
  hole: {
    vertex: {
      add: (
        key: "polygons" | "rectangles",
        featureId: string,
        vertexId: string,
        prevId: string,
        position: Position,
        holeId: string,
        t: number
      ): Change[] => [
        {
          id: idLib.shortId(),
          operation: "insert",
          path: [key, featureId, "holes", holeId, vertexId],
          property: { value: positionLib.toString(position), t },
          prevKey: prevId,
        },
      ],
      update: (
        key: "polygons" | "rectangles",
        featureId: string,
        holeId: string,
        vertexId: string,
        position: Position,
        t: number
      ): Change[] => [
        {
          id: idLib.shortId(),
          operation: "set",
          path: [key, featureId, "holes", holeId, vertexId],
          property: { value: positionLib.toString(position), t },
        },
      ],
      delete: (
        key: "polygons" | "rectangles",
        featureId: string,
        holeId: string,
        vertexId: string,
        t: number
      ): Change[] => [
        {
          id: idLib.shortId(),
          operation: "delete",
          path: [key, featureId, "holes", holeId, vertexId],
          property: { t },
        },
      ],
    },
    all: {
      delete: (key: "polygons" | "rectangles", featureId: string, holeId: string, t: number): Change[] => [
        {
          id: idLib.shortId(),
          operation: "delete",
          path: [key, featureId, "holes", holeId],
          property: { t },
        },
      ],
    },
  },
};

const Consumer = () => {
  const [wsStatus, dispatchWsStatus] = useReducer(websocketStatusReducer, "idle");

  const { getWebSocket, lastMessage, sendMessage } = useWebSocket(
    // "wss://appstage.larki.com.au/ws",
    "ws://localhost:8080",
  {
    onOpen: () => {
      dispatchWsStatus("connect");
    },
    onClose: () => {
      dispatchWsStatus("disconnect");
    },
  });

  // todo: can these be merged, client ID in the constructor?
  const client = useRef(new Client());
  const clientId = useRef<string | null>(null);

  const [mode, dispatchMode] = useReducer(modeReducer, "idle");

  const [tentativeSequencesData, setTentativeSequencesData] = useState<WorldStateT["tentativeShapes"]>(new Map());
  const [shapesData, setShapesData] = useState<WorldStateT["shapes"]>(new Map());

  // polygon-related state, should always be null if in any 'creating' mode
  const [vertexToDelete, setVertexToDelete] = useState<
    | PolygonVertexFeature
    | PathVertexFeature
    | BooleanVertexFeature<"polygon">
    | BooleanVertexFeature<"rectangle">
    | null
  >(null);
  const [midpointToCreateVertex, setMidpointToCreateVertex] = useState<MidpointToCreateVertex | null>(null);

  const [nextVertexId, setNextVertexId] = useState<string | null>(null);

  const isRedundantModeAction = useCallback((action: ModeAction) => modeReducer(mode, action) === mode, [mode]);

  const tick = useCallback((worldState: WorldState | null) => {
    if (!clientId.current) return;
    if (!worldState) return;
    const predicted = client.current.applyPredictions(worldState.getState(), worldState.getFulfilledPredictions());
    const newWorldState = new WorldState(predicted, worldState.getFulfilledPredictions());
    setTentativeSequencesData(newWorldState.getTentativeSequences());
    setShapesData(newWorldState.getShapes());
  }, []);

  const interpolator = useMemo(() => new WorldStateInterpolator(), []);

  useEffect(() => {
    // main loop
    const loop = () => {
      // if (interpolator.hasInterpolatedWorldStateChangedSince) {
      //   console.log('main');
      const worldState = interpolator.getInterpolatedWorldState();
      tick(worldState);
      // }
      requestAnimationFrame(loop);
    };
    const frame = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(frame);
  }, [interpolator, tick])

  const cursorLayer = new CursorLayer({
    clientId: clientId.current,
    data: Object.fromEntries(interpolator.interpolatedPositions),
  });

  const polygonLayer = useMemo(
    () =>
      new ShapeLayer({
        data: {
          shapes: shapesData,
          tentativeShapes: tentativeSequencesData,
        },
      }),
    [shapesData, tentativeSequencesData]
  );
  const deleteVertexLayer = useMemo(
    () => new DeleteVertexLayer({ data: vertexToDelete ? [{ type: "delete-vertex", vertex: vertexToDelete }] : [] }),
    [vertexToDelete]
  );
  const addVertexLayer = useMemo(
    () =>
      new AddVertexLayer({
        data: midpointToCreateVertex ? [{ type: "add-vertex", midpoint: midpointToCreateVertex }] : [],
      }),
    [midpointToCreateVertex]
  );

  const sendUpdate = useCallback(
    (changes: Change[]) => {
      sendMessage(BSON.serialize({ type: "update", changes } satisfies UpdateRequest));
    },
    [sendMessage]
  );
  const sendFrequentUpdate = useCallback(
    (change: FrequentChange) => {
      sendMessage(
        BSON.serialize({
          type: "frequent_update",
          change,
        } satisfies FrequentUpdateRequest)
      );
    },
    [sendMessage]
  );

  const sendThrottled1 = useThrottle(sendFrequentUpdate, { delay: 50, ensure: true });

  const onCursorMove = useCallback(
    (position: Position) => {
      if (wsStatus !== "synced") {
        console.warn("not synced");
        return;
      }
      const _clientId = clientId.current;
      invariant(_clientId);
      client.current.updateFrequent(sendThrottled1, {
        path: ["clients", _clientId, "position"],
        property: {
          value: positionLib.toString(position),
          t: Date.now(),
        },
      });
      tick(interpolator.getInterpolatedWorldState());
    },
    [wsStatus, sendThrottled1, tick, interpolator]
  );

  useEffect(() => {
    if (wsStatus !== "initializing") return;
    const ws = getWebSocket();
    invariant(ws && ws instanceof WebSocket);
    ws.binaryType = "arraybuffer";
    dispatchWsStatus("initialize");
  }, [wsStatus, getWebSocket]);

  useEffect(() => {
    if (wsStatus !== "joining") return;
    sendMessage(BSON.serialize({ type: "join" } satisfies JoinRequest));
  }, [sendMessage, wsStatus]);

  useEffect(() => {
    if (wsStatus !== "syncing") return;
    sendMessage(BSON.serialize({ type: "sync" } satisfies SyncRequest));
  }, [sendMessage, wsStatus]);

  useEffect(() => {
    if (wsStatus !== "joining" && wsStatus !== "syncing" && wsStatus !== "synced") return;
    if (!lastMessage?.data) {
      console.warn("no message");
      return;
    }
    invariant(lastMessage.data instanceof ArrayBuffer);
    const data = new Uint8Array(lastMessage.data);
    const message = BSON.deserialize(data);
    const response = RESPONSE_SCHEMA.parse(message);
    if (response.type === "join") {
      clientId.current = response.clientId;
      dispatchWsStatus("join");
      console.log("joined as ", clientId.current);
    } else if (response.type === "sync") {
      dispatchWsStatus("sync");
      const state = State.deserialize(response.state as never);
    } else if (response.type === "update") {
      if (wsStatus !== "synced") {
        console.warn("not synced");
        return;
      }
      const _clientId = clientId.current;
      invariant(_clientId);
      const state = State.deserialize(response.state as never);
      interpolator.addState(new WorldState(state, new Set(response.changeIds)));
    } else if (response.type === "frequent_update") {
      if (wsStatus !== "synced") {
        console.warn("not synced");
        return;
      }
      const _clientId = clientId.current;
      invariant(_clientId);
      const state = State.deserialize(response.state as never);
      interpolator.addState(new WorldState(state, new Set()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage, wsStatus]); // ignore cursorLayer

  useKeyPress(["0"], () => handleChangeMode("stop_creating"));
  useKeyPress(["1"], () => handleChangeMode("start_creating_polygon"));
  useKeyPress(["2"], () => handleChangeMode("start_creating_rectangle"));
  useKeyPress(["3"], () => handleChangeMode("start_creating_path"));
  useKeyPress(["4"], () => handleChangeMode("start_creating_boolean"));

  const deleteTentative = useCallback((_clientId: string): Change[] => {
    const t = Date.now();
    return [
      { id: idLib.shortId(), operation: "delete", path: ["clients", _clientId, "tentativeSequence"], property: { t } },
    ];
  }, []);

  const replaceVertexPositionInPolygonExterior = useCallback(
    (featureId: string, updatedPosition: Position, vertexId: string): Change[] => {
      const t = Date.now();
      const changes = changesLib.polygon.exterior.update(featureId, vertexId, updatedPosition, t);
      return changes;
    },
    []
  );

  const replaceVertexPositionInRectangleExterior = useCallback(
    (featureId: string, vertex: RectangleVertexFeature["properties"], updatedPosition: Position) => {
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === "rectangle");
      const t = Date.now();
      const neighborH = geom.exterior.find((v) => v.properties.vertexId === vertex.horizontalNeighborId);
      const neighborV = geom.exterior.find((v) => v.properties.vertexId === vertex.verticalNeighborId);
      invariant(neighborH);
      invariant(neighborV);
      const changes = [
        ...changesLib.rectangle.exterior.update(featureId, vertex.vertexId, updatedPosition, t),
        ...changesLib.rectangle.exterior.update(
          featureId,
          neighborH.properties.vertexId,
          [neighborH.geometry.coordinates[0], updatedPosition[1]],
          t
        ),
        ...changesLib.rectangle.exterior.update(
          featureId,
          neighborV.properties.vertexId,
          [updatedPosition[0], neighborV.geometry.coordinates[1]],
          t
        ),
      ];
      return changes;
    },
    [shapesData]
  );

  const replaceVertexPositionInHole = useCallback(
    <T extends "polygon" | "rectangle">(
      featureId: string,
      vertex: BooleanVertexFeature<T>["properties"],
      updatedPosition: Position
    ): Change[] => {
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === vertex.parentShapeType);
      const coords = geom.holes[vertex.holeIndex];
      const {
        properties: { holeId, vertexId },
      } = coords[vertex.vertexIndex];
      const t = Date.now();
      const changes = changesLib.hole.vertex.update(
        vertex.parentShapeType === "polygon" ? "polygons" : "rectangles",
        featureId,
        holeId,
        vertexId,
        updatedPosition,
        t
      );
      return changes;
    },
    [shapesData]
  );

  const replaceVertexPositionInPath = useCallback(
    (featureId: string, vertex: PathVertexFeature["properties"], updatedPosition: Position) => {
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === "path");
      const t = Date.now();
      const changes = changesLib.path.vertex.update(featureId, vertex.vertexId, updatedPosition, t);
      return changes;
    },
    [shapesData]
  );

  const deleteVertexInPolygonExterior = useCallback(
    (vertexToDelete: PolygonVertexFeature): Change[] => {
      const { featureId, vertexId } = vertexToDelete.properties;
      const t = Date.now();
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === "polygon");
      const L = geom.exterior.length;
      invariant(L >= 3);
      if (L === 3) {
        return changesLib.polygon.all.delete(featureId, t);
      } else {
        return changesLib.polygon.exterior.delete(featureId, vertexId, t);
      }
    },
    [shapesData]
  );

  const deleteVertexInHole = useCallback(
    <T extends "polygon" | "rectangle">(vertexToDelete: BooleanVertexFeature<T>): Change[] => {
      const { featureId, holeIndex, holeId, vertexId, parentShapeType } = vertexToDelete.properties;
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === parentShapeType);
      const hole = geom.holes[holeIndex];
      const L = hole.length;
      invariant(L >= 3);
      const t = Date.now();
      const key = parentShapeType === "polygon" ? "polygons" : "rectangles";
      if (L === 3) {
        return changesLib.hole.all.delete(key, featureId, holeId, t);
      } else {
        return changesLib.hole.vertex.delete(key, featureId, holeId, vertexId, t);
      }
    },
    [shapesData]
  );

  const deleteVertexInPath = useCallback(
    (vertexToDelete: PathVertexFeature): Change[] => {
      const { featureId } = vertexToDelete.properties;
      const t = Date.now();
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === "path");
      const L = geom.vertices.length;
      if (L === 2) {
        return changesLib.path.all.delete(featureId, t);
      }
      return changesLib.path.vertex.delete(featureId, vertexToDelete.properties.vertexId, t);
    },
    [shapesData]
  );

  const addVertexToPolygonExterior = useCallback(
    (midpoint: PolygonMidpointToCreateVertex) => {
      invariant(midpoint.before.properties.featureId === midpoint.after.properties.featureId);
      const t = Date.now();
      const newVertexId = idLib.shortId();
      const featureId = midpoint.before.properties.featureId;
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === "polygon");
      const changes = changesLib.polygon.exterior.add(
        featureId,
        newVertexId,
        midpoint.before.properties.vertexId,
        midpoint.position,
        t
      );
      return changes;
    },
    [shapesData]
  );

  const addVertexToPath = useCallback(
    (midpoint: PathMidpointToCreateVertex) => {
      invariant(midpoint.before.properties.featureId === midpoint.after.properties.featureId);
      const t = Date.now();
      const newVertexId = idLib.shortId();
      const { featureId } = midpoint.after.properties;
      const geom = shapesData.get(featureId);
      invariant(geom);
      invariant(geom.type === "path");
      return changesLib.path.vertex.add(
        featureId,
        newVertexId,
        midpoint.before.properties.vertexId,
        midpoint.position,
        t
      );
    },
    [shapesData]
  );

  const addVertexToHole = useCallback(
    (midpoint: PolygonBooleanMidpointToCreateVertex | RectangleBooleanMidpointToCreateVertex) => {
      invariant(midpoint.before.properties.featureId === midpoint.after.properties.featureId);
      const t = Date.now();
      const newVertexId = idLib.shortId();
      const { featureId, holeId, vertexId: beforeId } = midpoint.before.properties;
      const geom = shapesData.get(featureId);
      invariant(geom);
      console.log(midpoint);
      if (midpoint.parentShapeType === "polygon") {
        return changesLib.hole.vertex.add("polygons", featureId, newVertexId, beforeId, midpoint.position, holeId, t);
      } else if (midpoint.parentShapeType === "rectangle") {
        return changesLib.hole.vertex.add("rectangles", featureId, newVertexId, beforeId, midpoint.position, holeId, t);
      } else {
        invariant(false, "unsupported parent shape type");
      }
    },
    [shapesData]
  );

  const handleChangeMode = useCallback(
    (action: ModeAction) => {
      const _clientId = clientId.current;
      if (!_clientId) return;

      // if this would take us to the state we're already in, do nothing
      if (isRedundantModeAction(action)) return;

      // delete tentative
      client.current.update(sendUpdate, [...deleteTentative(_clientId)]);
      tick(interpolator.getInterpolatedWorldState());

      // change the mode
      dispatchMode(action);

      // drop next vertex id
      setNextVertexId(null);
    },
    [deleteTentative, interpolator, isRedundantModeAction, sendUpdate, tick]
  );

  const onClickAddPolygonVertexHandle = useCallback(
    (midpoint: PolygonMidpointToCreateVertex) => {
      client.current.update(sendUpdate, [...addVertexToPolygonExterior(midpoint)]);
      tick(interpolator.getInterpolatedWorldState());
      setMidpointToCreateVertex(null);
    },
    [addVertexToPolygonExterior, interpolator, sendUpdate, tick]
  );

  const onClickAddPathVertexHandle = useCallback(
    (midpoint: PathMidpointToCreateVertex) => {
      client.current.update(sendUpdate, [...addVertexToPath(midpoint)]);
      tick(interpolator.getInterpolatedWorldState());
      setMidpointToCreateVertex(null);
    },
    [addVertexToPath, interpolator, sendUpdate, tick]
  );

  const onClickAddBooleanVertexHandle = useCallback(
    (midpoint: PolygonBooleanMidpointToCreateVertex | RectangleBooleanMidpointToCreateVertex) => {
      client.current.update(sendUpdate, [...addVertexToHole(midpoint)]);
      tick(interpolator.getInterpolatedWorldState());
      setMidpointToCreateVertex(null);
    },
    [addVertexToHole, interpolator, sendUpdate, tick]
  );

  const onClickDeletePolygonVertexHandle = useCallback(
    (vertex: PolygonVertexFeature) => {
      // delete vertex
      client.current.update(sendUpdate, [...deleteVertexInPolygonExterior(vertex)]);
      tick(interpolator.getInterpolatedWorldState());
      setVertexToDelete(null);
    },
    [deleteVertexInPolygonExterior, interpolator, sendUpdate, tick]
  );

  const onClickDeletePathVertexHandle = useCallback(
    (vertex: PathVertexFeature) => {
      // delete vertex
      client.current.update(sendUpdate, [...deleteVertexInPath(vertex)]);
      tick(interpolator.getInterpolatedWorldState());
      setVertexToDelete(null);
    },
    [deleteVertexInPath, interpolator, sendUpdate, tick]
  );

  const onClickDeleteRectangleHoleVertexHandle = useCallback(
    (vertex: BooleanVertexFeature<"rectangle">) => {
      // delete vertex
      client.current.update(sendUpdate, [...deleteVertexInHole(vertex)]);
      tick(interpolator.getInterpolatedWorldState());
      setVertexToDelete(null);
    },
    [deleteVertexInHole, interpolator, sendUpdate, tick]
  );

  const onClickDeletePolygonHoleVertexHandle = useCallback(
    (vertex: BooleanVertexFeature<"polygon">) => {
      // delete vertex
      client.current.update(sendUpdate, [...deleteVertexInHole(vertex)]);
      tick(interpolator.getInterpolatedWorldState());
      setVertexToDelete(null);
    },
    [deleteVertexInHole, interpolator, sendUpdate, tick]
  );

  /**
   * ASSUMES: current tentative shape is a polygon
   */
  const onClickTentativePolygonVertex = useCallback(
    (_clientId: string) => {
      invariant(mode === "creating_polygon");

      // finish tentative sequence, create shape
      const tentative = tentativeSequencesData.get(_clientId);
      invariant(tentative);
      const tentativeShape = tentative.shape;

      if (tentativeShape) {
        invariant(tentativeShape.type === "polygon");
        const tentativeSequence = Object.entries(tentativeShape.vertices);
        if (tentativeSequence.length < 3) return;
        const newShapeId = idLib.shortId();
        const t = Date.now();
        client.current.update(
          sendUpdate,
          [
            // delete tentative
            ...deleteTentative(_clientId),

            // create shape
            ...tentativeSequence.map(([vId, v], i): Change => {
              const id = idLib.shortId();
              const property = { value: positionLib.toString(v.geometry.coordinates), t };
              const path = ["polygons", newShapeId, "exterior", vId];
              if (i === 0) {
                return {
                  id,
                  operation: "head",
                  path,
                  property,
                };
              } else {
                return {
                  id,
                  operation: "insert",
                  path,
                  property,
                  prevKey: tentativeSequence[i - 1][0],
                };
              }
            }),
          ],
          { log: true }
        );
        tick(interpolator.getInterpolatedWorldState());
        setNextVertexId(null);
      }
    },
    [deleteTentative, interpolator, mode, sendUpdate, tentativeSequencesData, tick]
  );

  const onClickTentativePathVertex = useCallback(
    (_clientId: string) => {
      invariant(mode === "creating_path");

      // finish tentative sequence, create shape
      const tentative = tentativeSequencesData.get(_clientId);
      invariant(tentative);
      const shape = tentative.shape;
      invariant(shape && shape.type === "path");
      const t = Date.now();
      const newShapeId = idLib.shortId();
      client.current.update(sendUpdate, [
        // delete tentative
        ...deleteTentative(_clientId),

        // create shape
        ...shape.vertices.map(({ geometry: { coordinates }, properties: { vertexId } }, i): Change => {
          const id = idLib.shortId();
          const property = { value: positionLib.toString(coordinates), t };
          const path = ["paths", newShapeId, vertexId];
          if (i === 0) {
            return {
              id,
              operation: "head",
              path,
              property,
            };
          } else {
            return {
              id,
              operation: "insert",
              path,
              property,
              prevKey: shape.vertices[i - 1].properties.vertexId,
            };
          }
        }),
      ]);
      tick(interpolator.getInterpolatedWorldState());
      setNextVertexId(null);
    },
    [deleteTentative, interpolator, mode, sendUpdate, tentativeSequencesData, tick]
  );

  const onClickInsideShape = useCallback(
    (_clientId: string, coordinate: Position, parentShapeId: string, mapShapeType: "polygon" | "rectangle") => {
      if (mode !== "creating_boolean") return;
      const shape = shapesData.get(parentShapeId);
      invariant(shape);
      invariant(shape.type === mapShapeType, `got ${shape.type}`);

      const vertexId = nextVertexId ?? idLib.shortId();
      const t = Date.now();

      const tentative = tentativeSequencesData.get(_clientId);
      const lengthBefore = tentative?.sequenceIds.length ?? 0;

      client.current.update(sendUpdate, [
        {
          operation: "set",
          id: idLib.shortId(),
          path: ["clients", _clientId, "tentativeSequence", vertexId],
          property: {
            value: vertexLib.toString({
              position: coordinate,
              positionIndices: [lengthBefore, 0],
            }),
            t,
          },
        },
        {
          id: idLib.shortId(),
          operation: "set",
          path: ["clients", _clientId, "tentativeType"],
          property: { value: SHAPE_TYPE.boolean, t },
        },
        // TODO: accept a NONE value for when no tentative parent is chosen
        {
          id: idLib.shortId(),
          operation: "set",
          path: ["clients", _clientId, "tentativeParentShapeId"],
          property: { value: parentShapeId, t },
        },
      ]);

      tick(interpolator.getInterpolatedWorldState());
    },
    [interpolator, mode, nextVertexId, sendUpdate, shapesData, tentativeSequencesData, tick]
  );

  const onClickTentativeBooleanVertex = useCallback(
    (_clientId: string) => {
      invariant(mode === "creating_boolean");

      const tentative = tentativeSequencesData.get(_clientId);
      invariant(tentative);
      const tentativeShape = tentative.shape;
      invariant(tentativeShape && tentativeShape.type === "boolean");
      const parent = shapesData.get(tentativeShape.parentShapeId);
      invariant(parent);
      invariant(parent.type === "polygon" || parent.type === "rectangle", `unsupported parent type ${parent.type}`);
      const t = Date.now();

      const changes = deleteTentative(_clientId); // delete tentative

      const shapeKey = parent.type === "polygon" ? "polygons" : "rectangles";
      const vertices = Object.values(tentativeShape.vertices);
      const holeId = idLib.shortId();

      changes.push(
        ...vertices.map(({ geometry: { coordinates }, properties: { vertexId } }, i): Change => {
          const id = idLib.shortId();
          const path = [shapeKey, tentativeShape.parentShapeId, "holes", holeId, vertexId];
          if (i === 0) {
            return {
              id,
              operation: "head",
              path,
              property: { value: positionLib.toString(coordinates), t },
            };
          } else {
            return {
              id,
              operation: "insert",
              path,
              property: { value: positionLib.toString(coordinates), t },
              prevKey: vertices[i - 1].properties.vertexId,
            };
          }
        })
      );

      client.current.update(sendUpdate, changes, { log: true });

      tick(interpolator.getInterpolatedWorldState());
      setNextVertexId(null);
    },
    [deleteTentative, interpolator, mode, sendUpdate, shapesData, tentativeSequencesData, tick]
  );

  const onClickOnMap = useCallback(
    (_clientId: string, coordinate: Position) => {
      setVertexToDelete(null);
      setMidpointToCreateVertex(null);

      if (!isModeCreating(mode)) return;

      const tentative = tentativeSequencesData.get(_clientId);
      const lengthBefore = tentative?.sequenceIds.length ?? 0;

      // we could be in "creating_boolean" mode without a tentative shape existing, meaning no parent shape has been clicked on
      if (mode === "creating_boolean") {
        if (!tentative?.shape) return;
        invariant(tentative.shape.type === "boolean");
      }

      // if we're currently creating a rectangle and have already placed one vertex down, create the rectangle shape
      if (mode === "creating_rectangle") {
        if (lengthBefore > 0) {
          if (!tentative?.shape) return;
          invariant(tentative.shape.type === "rectangle");
          invariant(tentative.shape.final);

          // complete the shape
          const t = Date.now();
          const newShapeId = idLib.shortId();
          client.current.update(sendUpdate, [
            // delete tentative
            ...deleteTentative(_clientId),

            // create the shape
            ...[tentative.shape.initial, tentative.shape.final, ...Object.values(tentative.shape.derived)].flatMap(
              ({ geometry: { coordinates }, properties: { vertexId, corner } }): Change[] => {
                return [
                  {
                    id: idLib.shortId(),
                    operation: "set",
                    path: ["rectangles", newShapeId, "exterior", vertexId, "position"],
                    property: { value: positionLib.toString(coordinates), t },
                  },
                  {
                    id: idLib.shortId(),
                    operation: "set",
                    path: ["rectangles", newShapeId, "exterior", vertexId, "corner"],
                    property: { value: rectangleLib.cornerToString(corner), t },
                  },
                ];
              }
            ),
          ]);
          tick(interpolator.getInterpolatedWorldState());
          setNextVertexId(null);
          return;
        }
      }

      // otherwise, create a tentative vertex
      const vertexId = nextVertexId ?? idLib.shortId();
      const t = Date.now();
      const shapeType = creatingModeToTentativeShapeType(mode);
      client.current.update(sendUpdate, [
        {
          id: idLib.shortId(),
          operation: "set",
          path: ["clients", _clientId, "tentativeSequence", vertexId],
          property: {
            value: vertexLib.toString({
              position: coordinate,
              positionIndices: [lengthBefore, 0],
            }),
            t,
          },
        },
        {
          id: idLib.shortId(),
          operation: "set",
          path: ["clients", _clientId, "tentativeType"],
          property: { value: shapeType, t },
        },
      ]);
      tick(interpolator.getInterpolatedWorldState());

      const newVertexId = idLib.shortId();
      setNextVertexId(newVertexId);
    },
    [deleteTentative, interpolator, mode, nextVertexId, sendUpdate, tentativeSequencesData, tick]
  );

  return (
    <DeckGL
      layers={[cursorLayer, polygonLayer, deleteVertexLayer, addVertexLayer]}
      initialViewState={{
        longitude: INITIAL_MAP_OPTIONS.CENTER[0],
        latitude: INITIAL_MAP_OPTIONS.CENTER[1],
        zoom: INITIAL_MAP_OPTIONS.ZOOM,
      }}
      onViewStateChange={visGm.limitTiltRange}
      controller={{
        doubleClickZoom: false, // fixes delay
        scrollZoom: {
          smooth: true,
          speed: 0.005,
        },
      }}
      onDragStart={(info: PickingInfoT) => {
        const _clientId = clientId.current;
        if (!_clientId) return;
        if (info.handle && info.handle.type === "shape") {
          const t = Date.now();
          client.current.update(sendUpdate, [
            {
              id: idLib.shortId(),
              operation: "set",
              path: ["clients", _clientId, "draggingShapeVertexId"],
              property: {
                value: info.handle.vertexId,
                t,
              },
            },
          ]);
          setVertexToDelete(null);
          setMidpointToCreateVertex(null);
        }
      }}
      onDrag={(info: PickingInfoT, event) => {
        const _clientId = clientId.current;
        if (!_clientId) return;
        const { coordinate } = info;
        if (coordinate) {
          if (info.handle) {
            if (info.handle.type === "shape") {
              event.stopImmediatePropagation();
              onCursorMove(coordinate);
            }
          }
        }
      }}
      onDragEnd={(info: PickingInfoT) => {
        const _clientId = clientId.current;
        if (!_clientId) return;
        const { coordinate } = info;
        if (coordinate) {
          if (info.handle) {
            if (info.handle.type === "shape") {
              if (info.handle.shapeType === "polygon") {
                const t = Date.now();
                client.current.update(sendUpdate, [
                  ...replaceVertexPositionInPolygonExterior(info.handle.featureId, coordinate, info.handle.vertexId),
                  {
                    operation: "set",
                    id: idLib.shortId(),
                    path: ["clients", _clientId, "draggingShapeVertexId"],
                    property: { value: "NONE", t },
                  },
                ]);
                tick(interpolator.getInterpolatedWorldState());
              } else if (info.handle.shapeType === "rectangle") {
                const t = Date.now();
                client.current.update(
                  sendUpdate,
                  [
                    ...replaceVertexPositionInRectangleExterior(info.handle.featureId, info.handle, coordinate),
                    {
                      id: idLib.shortId(),
                      operation: "set",
                      path: ["clients", _clientId, "draggingShapeVertexId"],
                      property: { value: "NONE", t },
                    },
                  ],
                  { log: true }
                );
                tick(interpolator.getInterpolatedWorldState());
              } else if (info.handle.shapeType === "boolean") {
                const t = Date.now();
                client.current.update(sendUpdate, [
                  ...replaceVertexPositionInHole(info.handle.featureId, info.handle, coordinate),
                  {
                    id: idLib.shortId(),
                    operation: "set",
                    path: ["clients", _clientId, "draggingShapeVertexId"],
                    property: { value: "NONE", t },
                  },
                ]);
                tick(interpolator.getInterpolatedWorldState());
              } else if (info.handle.shapeType === "path") {
                const t = Date.now();
                client.current.update(sendUpdate, [
                  ...replaceVertexPositionInPath(info.handle.featureId, info.handle, coordinate),
                  {
                    id: idLib.shortId(),
                    operation: "set",
                    path: ["clients", _clientId, "draggingShapeVertexId"],
                    property: { value: "NONE", t },
                  },
                ]);
                tick(interpolator.getInterpolatedWorldState());
              }
            }
          }
        }
      }}
      onClick={(info: PickingInfoT) => {
        const _clientId = clientId.current;
        if (!_clientId) return;
        if (info.shape) {
          if (!info.coordinate) return;
          if (info.shape.type === "polygon" || info.shape.type === "rectangle") {
            return onClickInsideShape(_clientId, info.coordinate, info.shape.id, info.shape.type);
          }
        } else if (info.handle) {
          if (info.handle.type === "add-vertex") {
            if (info.handle.midpoint.shapeType === "polygon") {
              return onClickAddPolygonVertexHandle(info.handle.midpoint);
            } else if (info.handle.midpoint.shapeType === "path") {
              return onClickAddPathVertexHandle(info.handle.midpoint);
            } else if (info.handle.midpoint.shapeType === "boolean") {
              return onClickAddBooleanVertexHandle(info.handle.midpoint);
            }
          } else if (info.handle.type === "delete-vertex") {
            const { vertex } = info.handle;
            if (vertex.properties.shapeType === "polygon") {
              return onClickDeletePolygonVertexHandle(vertex as PolygonVertexFeature);
            } else if (vertex.properties.shapeType === "path") {
              return onClickDeletePathVertexHandle(vertex as PathVertexFeature);
            } else if (vertex.properties.shapeType === "boolean") {
              if (vertex.properties.parentShapeType === "rectangle") {
                return onClickDeleteRectangleHoleVertexHandle(vertex as BooleanVertexFeature<"rectangle">);
              } else {
                invariant(vertex.properties.parentShapeType === "polygon");
                return onClickDeletePolygonHoleVertexHandle(vertex as BooleanVertexFeature<"polygon">);
              }
            }
          } else if (info.handle.type === "tentative") {
            if (info.handle.shapeType === "polygon") {
              return onClickTentativePolygonVertex(_clientId);
            } else if (info.handle.shapeType === "path") {
              return onClickTentativePathVertex(_clientId);
            } else if (info.handle.shapeType === "boolean") {
              return onClickTentativeBooleanVertex(_clientId);
            }
          }
        } else {
          if (info.coordinate) {
            return onClickOnMap(_clientId, info.coordinate);
          }
        }
      }}
      onHover={(info: PickingInfoT) => {
        const _clientId = clientId.current;
        if (!_clientId) {
          console.warn("no client id");
          return;
        }
        const { coordinate } = info;
        if (!coordinate) {
          console.warn("no coordinate");
          return;
        }
        onCursorMove(coordinate);
        if (!nextVertexId) {
          // not in tentative "mode"
          if (!info.handle) {
            // we should show the popup that has a smaller distance
            let show:
              | {
                  type: "delete";
                  vertex:
                    | PolygonVertexFeature
                    | PathVertexFeature
                    | BooleanVertexFeature<"rectangle">
                    | BooleanVertexFeature<"polygon">;
                  xyDistance: number;
                }
              | { type: "add"; midpoint: MidpointToCreateVertex }
              | null = null;

            const closest = polygonLayer.getClosestShapeVertex(coordinate);
            if (closest && closest.xyDistance <= MIN_DISTANCE_TO_DELETE_VERTEX_PIXELS) {
              show = { type: "delete", vertex: closest.vertex, xyDistance: closest.xyDistance };
            }

            const midpoint = polygonLayer.getClosestMidpoint(coordinate);
            if (
              midpoint &&
              midpoint.xyDistance <= MIN_DISTANCE_TO_CREATE_VERTEX_PIXELS &&
              (!show || midpoint.xyDistance < show.xyDistance)
            ) {
              show = { type: "add", midpoint: midpoint };
            }

            if (show) {
              if (show.type === "delete") {
                setVertexToDelete(show.vertex);
                setMidpointToCreateVertex(null);
              } else if (show.type === "add") {
                setVertexToDelete(null);
                setMidpointToCreateVertex(show.midpoint);
              }
            } else {
              setVertexToDelete(null);
              setMidpointToCreateVertex(null);
            }
          }
        }
      }}
    >
      <visGm.Map id={MAP_ID} {...GM_MAP_OPTIONS} />
    </DeckGL>
  );
};
const Provider = () => {
  return (
    <visGm.APIProvider apiKey={process.env.GCP_MAPS_API_KEY!}>
      <Consumer />
    </visGm.APIProvider>
  );
};
export default Provider;
