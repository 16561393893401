import { CompositeLayer } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';

import { svgToDataUrl } from './svg';
import { Position } from 'geojson';
import { WorldState, WorldStateT } from './worldState';

// type CursorLayerState = {
//   interpolator: WorldStateInterpolator;
// };
// type CursorLayerData = {
//   // id: string;
//   position: Position;
// };

const CURSOR_SVG = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4 0l16 12.279-6.951 1.17 4.325 8.817-3.596 1.734-4.35-8.879-5.428 4.702z"/></svg>`;

type Projector = (xy: Position) => Position;

// class WorldStateInterpolator {
//   private interpolators: Record<string, PointInterpolator> = {};
//   private cb: (state: WorldState) => void;
//   private project: Projector;
//   private unproject: Projector;

//   constructor(
//     cb: (state: WorldState) => void,
//     project: Projector,
//     unproject: Projector
//   ) {
//     this.cb = cb;
//     this.project = project;
//     this.unproject = unproject;
//   }

//   addState(state: WorldState) {
//     if (!this.project) {
//       console.warn("no projector");
//       return;
//     }
//     const cursors = state.getCursors();
//     for (const [clientId, position] of Object.entries(cursors)) {
//       (this.interpolators[clientId] ??= new PointInterpolator((point) => {
//         if (!this.unproject) {
//           console.warn("no unprojector");
//           return;
//         }
//         this.cb(state.interpolate(clientId, this.unproject(point)));
//       })).addPoint(this.project(position));
//     }
//   }
// }

type CursorLayerProps = {
  clientId: string | null;
  data: Record<string, Position>;
  cb: (interpolated: WorldState) => void;
};
export default class CursorLayer extends CompositeLayer<CursorLayerProps> {
  renderLayers() {
    const data = Object.entries(this.props.data)
      .filter(([cId]) => cId !== this.props.clientId)
      .map(([, position]) => ({
        position,
      }));
    return new IconLayer<{ position: Position }>({
      id: 'cursor-layer',
      data,
      getIcon: () => ({
        url: svgToDataUrl(CURSOR_SVG),
        width: 24,
        height: 24,
      }),
      getPixelOffset: [8, 10],
      getSize: 24,
      getPosition: (c) => c.position as [number, number],
    });
  }
}
