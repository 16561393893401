import { CompositeLayer, GetPickingInfoParams, PickingInfo } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";
import { svgToDataUrl } from "./svg";
import { MidpointToCreateVertex } from "./RealtimeMap";

type PickingInfoDataT = { type: "add-vertex"; midpoint: MidpointToCreateVertex };
type PickingExtraInfoT = { handle: PickingInfoDataT; shape: undefined };
type PickingInfoParamsT = GetPickingInfoParams<PickingInfoDataT, PickingExtraInfoT>;

export type AddVertexLayerPickingInfoT = PickingInfo<PickingInfoDataT, PickingExtraInfoT>;

type Props = {
  data: PickingInfoDataT[];
};

export default class AddVertexLayer extends CompositeLayer<Props> {
  getPickingInfo({ info }: PickingInfoParamsT): AddVertexLayerPickingInfoT {
    if (info.object?.type === "add-vertex") {
      info.handle = info.object;
    }
    return info;
  }

  renderLayers() {
    return [
      new IconLayer<PickingInfoDataT>({
        id: "add-vertex-layer", // important, errors otherwise
        data: this.props.data,
        getSize: 36,
        getIcon: () => ({
          url: svgToDataUrl(PLUS_SVG),
          width: 36,
          height: 36,
        }),
        getPixelOffset: [0, -24],
        getPosition: (d) => d.midpoint.position as [number, number],
        pickable: true,
      }),
    ];
  }
}

const PLUS_SVG = `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="18" fill="#fff"/>
<g transform="translate(6,6)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 21V3H14V21H10Z" fill="#31A283"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21 14L3 14L3 10L21 10L21 14Z" fill="#31A283"/>
</g>
</svg>`;
