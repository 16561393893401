import { useCallback, useEffect, useRef } from "react";

export const useKeyPress = (keys: string[], callback: () => void) => {
  // https://www.epicreact.dev/the-latest-ref-pattern-in-react
  // "latest ref" pattern we want to track a value without re-rendering every time that value changes (unlike useState)
  // more details: https://www.epicreact.dev/how-react-uses-closures-to-avoid-bugs
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (keys.includes(e.key)) {
        callbackRef.current();
      }
    },
    [keys]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
};
