import { Color, CompositeLayer, GetPickingInfoParams, PickingInfo } from "@deck.gl/core";
import { GeoJsonLayer } from "@deck.gl/layers";
import invariant from "tiny-invariant";
import * as turf from "@turf/turf";
import {
  BBox,
  Feature,
  GeoJsonProperties,
  GeoJsonTypes,
  Geometry,
  LineString,
  MultiPolygon,
  Point,
  Polygon,
  Position,
} from "geojson";

import { Tentative, WorldStateT } from "./worldState";
import { MidpointToCreateVertex, PolygonMidpointToCreateVertex } from "./RealtimeMap";
import {
  BooleanVertexFeature,
  PathVertexFeature,
  PolygonVertexFeature,
  RectangleVertexFeature,
  TentativeBoolean,
  TentativeBooleanVertexFeature,
  TentativeFinalVertexFeature,
  TentativePathVertexFeature,
  TentativePolygonVertexFeature,
  TentativeRectangleVertexFeature,
} from "./lib/shape";
import { arrayLib } from "./lib/array";
import { object } from "zod";

type PickingInfoDataT =
  | TentativePolygonVertexFeature
  | TentativeRectangleVertexFeature
  | TentativePathVertexFeature
  | PolygonVertexFeature
  | BooleanVertexFeature<"rectangle">
  | BooleanVertexFeature<"polygon">
  | RectangleVertexFeature
  | Feature<Polygon, null>
  | Feature<MultiPolygon, null>
  | Feature<LineString, null>;
type PickingExtraInfoT = {
  handle?:
    | TentativePolygonVertexFeature["properties"]
    | TentativeRectangleVertexFeature["properties"]
    | TentativePathVertexFeature["properties"]
    | TentativeBooleanVertexFeature["properties"]
    | PolygonVertexFeature["properties"]
    | RectangleVertexFeature["properties"]
    | PathVertexFeature["properties"]
    | BooleanVertexFeature<"rectangle">["properties"]
    | BooleanVertexFeature<"polygon">["properties"];
  shape?: { type: "polygon" | "rectangle" | "path"; id: string };
};
type PickingInfoParamsT = GetPickingInfoParams<PickingInfoDataT, PickingExtraInfoT>;

export type ShapeLayerPickingInfoT = PickingInfo<PickingInfoDataT, PickingExtraInfoT>;

type TentativeSequence = (
  | TentativePolygonVertexFeature
  | TentativeFinalVertexFeature
  | TentativeRectangleVertexFeature
  | TentativePathVertexFeature
)[];

type Props = {
  data: Pick<WorldStateT, "shapes" | "tentativeShapes">;
};

const tentativeProperties = { shapeVariant: "tentative" } as const;

const tentativePolygonFeatureFromSequence = (seq: TentativeSequence) => {
  invariant(seq.length >= 2, "sequence needs >= 2 elements");
  const coords = seq.map((feature) => feature.geometry.coordinates);
  if (seq.length === 2) {
    return turf.lineString(coords, tentativeProperties);
  }
  return turf.polygon([[...coords, coords[0]]], tentativeProperties);
};

const tentativeBooleanFeatureFromSequence = (seq: TentativeSequence) => {
  if (seq.length !== 2) return null;
  const coords = seq.map((feature) => feature.geometry.coordinates);
  return turf.lineString(coords, tentativeProperties);
};

const rectangleBoundsFromSequence = (seq: TentativeSequence): BBox => {
  invariant(seq.length === 2, "rectangle needs 2 vertices");
  const [vA, vB] = seq;
  const [x0, y0] = vA.geometry.coordinates;
  const [x1, y1] = vB.geometry.coordinates;
  return [Math.min(x0, x1), Math.min(y0, y1), Math.max(x0, x1), Math.max(y0, y1)];
};

const tentativeRectangleFeatureFromSequence = (seq: TentativeSequence) => {
  const bounds = rectangleBoundsFromSequence(seq);
  return turf.bboxPolygon(bounds, { properties: tentativeProperties });
};

const pathFeatureFromSequence = <T extends object>(seq: Feature<Point>[], properties: T) => {
  invariant(seq.length >= 2);
  const PATH_WIDTH_METERS = 20;
  const MIN_DISTANCE_TO_SHOW_FINAL_SECTOR_METERS = 1;

  const elements: Feature<Polygon | MultiPolygon>[] = [];
  for (let i = 0; i < seq.length - 1; i++) {
    const p1 = turf.point(turf.getCoord(seq[i]));
    const p2 = turf.point(turf.getCoord(seq[i + 1]));
    if (i == seq.length - 2) {
      const dst = turf.distance(p1, p2, { units: "meters" });
      if (dst < MIN_DISTANCE_TO_SHOW_FINAL_SECTOR_METERS) {
        continue;
      }
    }
    const bearing = turf.bearing(p1, p2);
    const p0a = turf.destination(p1, PATH_WIDTH_METERS, bearing + 90, {
      units: "meters",
    });
    const p0b = turf.destination(p1, PATH_WIDTH_METERS, bearing - 90, {
      units: "meters",
    });
    const p1a = turf.destination(p2, PATH_WIDTH_METERS, bearing + 90, {
      units: "meters",
    });
    const p1b = turf.destination(p2, PATH_WIDTH_METERS, bearing - 90, {
      units: "meters",
    });
    const rect = turf.polygon(
      [[turf.getCoord(p0a), turf.getCoord(p0b), turf.getCoord(p1b), turf.getCoord(p1a), turf.getCoord(p0a)]],
      properties
    );
    // buffer slightly to ensure overlap happens
    let rectBuffered = turf.buffer(rect, 0.001, { units: "meters" }) as Feature<Polygon | MultiPolygon, T>;
    invariant(rectBuffered);
    rectBuffered = { ...rectBuffered, properties };

    elements.push(rectBuffered);
    if (i > 0) {
      const p0 = turf.point(turf.getCoord(seq[i - 1]));
      const priorBearing = turf.bearing(p0, p1);
      // get the signed-angle difference in [-180,180] https://stackoverflow.com/a/61901403
      const diff = ((bearing - priorBearing + 360 + 180) % 360) - 180;
      const _to360 = (angle: number) => (angle + 360) % 360;
      const _to180 = (angle: number) => (angle > 180 ? angle - 360 : angle < -180 ? angle + 360 : angle);
      const angle0 = _to360(priorBearing);
      const angle1 = _to360(bearing);
      const a0 = _to180(_to360(diff >= 0 ? angle0 - 90 : angle1 + 90));
      const a1 = _to180(_to360(diff >= 0 ? angle1 - 90 : angle0 + 90));
      const controlPointSector = turf.sector(p1, PATH_WIDTH_METERS, a0, a1, {
        units: "meters",
      });
      const controlPointSectorBuffered = controlPointSector;
      invariant(controlPointSectorBuffered);
      elements.push(controlPointSectorBuffered);
    }
  }
  if (elements.length === 0) return null;
  const surrounding =
    elements.length === 1 ? elements[0] : turf.union(turf.featureCollection(elements), { properties });
  invariant(surrounding);
  return surrounding;
};

const geometryLib = {
  /**
   * turf.difference but keeps the properties of the 1st argument
   */
  difference: <P extends GeoJsonProperties>(
    a: Feature<Polygon | MultiPolygon, P>,
    b: Feature<Polygon | MultiPolygon>
  ): Feature<Polygon | MultiPolygon, P> | null => {
    const diff = turf.difference(turf.featureCollection([a, b]));
    if (!diff) return null;
    const diff2: Feature<Polygon | MultiPolygon, P> = { ...diff, properties: a.properties };
    return diff2;
  },
  getCoords: (geometry: Feature<Point>[]) => {
    return geometry.map((p) => p.geometry.coordinates);
  },
};

const toFeatureCollection = (data: Props["data"]) => {
  // filter out booleans of a deleted parent
  const tentativeHandles = [...data.tentativeShapes.values()]
    .map(({ shape }) => {
      if (!shape) return null;
      if (shape.type === "polygon") {
        return Object.values(shape.vertices);
      } else if (shape.type === "rectangle") {
        return [shape.initial, ...Object.values(shape.derived)];
      } else if (shape.type === "path") {
        return shape.vertices;
      } else if (shape.type === "boolean") {
        return Object.values(shape.vertices);
      }
      throw new Error("unknown shape");
    })
    .flat()
    .filter(arrayLib.filterNonNullAndNonUndefined);
  const tentativeBooleanFeatures = new Map(
    [...data.tentativeShapes]
      .filter((entry): entry is [string, { sequenceIds: string[]; shape: TentativeBoolean }] => {
        return entry[1].shape?.type === "boolean";
      })
      .map(([, tentative]) => {
        const v = Object.values(tentative.shape.vertices).map(turf.getCoord);
        if (tentative.shape.final) {
          v.push(turf.getCoord(tentative.shape.final));
        }
        return [tentative.shape?.parentShapeId, v];
      })
  );
  const tentativeFeatures = [...data.tentativeShapes.values()]
    .map(({ shape }) => {
      if (!shape) return null;
      if (shape.type === "polygon") {
        const v = Object.values(shape.vertices);
        return { type: shape.type, sequence: shape.final ? [...v, shape.final] : v };
      } else if (shape.type === "rectangle") {
        return {
          type: shape.type,
          sequence: shape.final ? [shape.initial, shape.final] : [shape.initial],
        };
      } else if (shape.type === "path") {
        return {
          type: shape.type,
          sequence: shape.final ? [...shape.vertices, shape.final] : shape.vertices,
        };
      } else if (shape.type === "boolean") {
        const sequence = shape.final ? [...Object.values(shape.vertices), shape.final] : Object.values(shape.vertices);
        return { type: shape.type, sequence: sequence };
      }
      throw new Error("unknown shape");
    })
    .filter(arrayLib.filterNonNull)
    .filter(({ sequence }) => sequence.length >= 2)
    .map(({ type, sequence }) => {
      if (type === "polygon") {
        return tentativePolygonFeatureFromSequence(sequence);
      } else if (type === "rectangle") {
        return tentativeRectangleFeatureFromSequence(sequence);
      } else if (type === "path") {
        return pathFeatureFromSequence(sequence, tentativeProperties);
      } else if (type === "boolean") {
        return tentativeBooleanFeatureFromSequence(sequence);
      }
      throw new Error(`unknown type ${type}`);
    })
    .filter(arrayLib.filterNonNull);
  const shapeFeatures = [...data.shapes]
    .map(([shapeId, shape]) => {
      const shapeProperties = { mapShapeType: shape.type, shapeId };
      if (shape.type === "polygon") {
        if (shape.exterior.length < 3) return null;
        const exterior = geometryLib.getCoords(shape.exterior);
        const holes = shape.holes.map(geometryLib.getCoords);
        if (tentativeBooleanFeatures.has(shapeId)) {
          const tentativeBoolean = tentativeBooleanFeatures.get(shapeId)!;
          if (tentativeBoolean.length >= 3) {
            holes.push(tentativeBoolean);
          }
        }
        let polygon: Feature<Polygon | MultiPolygon, typeof shapeProperties> | null = turf.polygon(
          [[...exterior, exterior[0]]],
          shapeProperties
        );
        for (const hole of holes) {
          if (polygon && hole?.length > 0) {
            polygon = geometryLib.difference(polygon, turf.polygon([[...hole, hole[0]]]));
          }
        }
        return polygon;
      } else if (shape.type === "rectangle") {
        const vs = shape.exterior;
        let polygon: Feature<Polygon | MultiPolygon, typeof shapeProperties> | null = turf.polygon(
          [geometryLib.getCoords([...vs, vs[0]])],
          shapeProperties
        );
        const holes = shape.holes.map(geometryLib.getCoords);
        if (tentativeBooleanFeatures.has(shapeId)) {
          const tentativeBoolean = tentativeBooleanFeatures.get(shapeId)!;
          if (tentativeBoolean.length >= 3) {
            holes.push(tentativeBoolean);
          }
        }
        for (const hole of holes) {
          if (polygon && hole?.length > 0) {
            try {
              polygon = geometryLib.difference(polygon, turf.polygon([[...hole, hole[0]]]));
            } catch (e) {
              console.error(hole);
              throw e;
            }
          }
        }
        return polygon;
      } else if (shape.type === "path") {
        if (shape.vertices.length < 2) return null;
        return pathFeatureFromSequence(shape.vertices, shapeProperties);
      }
      throw new Error("unknown shape");
    })
    .filter(arrayLib.filterNonNull);
  const shapeHandles = [...data.shapes.values()]
    .map((shape) => {
      if (shape.type === "polygon") {
        return [shape.exterior, ...shape.holes];
      } else if (shape.type === "rectangle") {
        return [shape.exterior, ...shape.holes];
      } else if (shape.type === "path") {
        return [shape.vertices];
      }
      throw new Error("unknown shape");
    })
    .flat(2);
  return turf.featureCollection<Point | LineString | Polygon | MultiPolygon>([
    ...tentativeHandles,
    ...tentativeFeatures,
    ...shapeFeatures,
    ...shapeHandles,
  ]);
};

export default class ShapeLayer extends CompositeLayer<Props> {
  getPickingInfo({ info }: PickingInfoParamsT): ShapeLayerPickingInfoT {
    const data = info.object;
    if (data?.type === "Feature") {
      if (data.geometry.type === "Polygon" || data.geometry.type === "MultiPolygon") {
        if ((data.properties as any).mapShapeType) {
          info.shape = { type: (data.properties as any).mapShapeType, id: (data.properties as any).shapeId };
        }
      } else if (data.geometry.type === "LineString") {
        //
      } else if (data.properties?.type === "tentative") {
        info.handle = data.properties;
      } else if (data.properties?.type === "shape") {
        info.handle = data.properties;
      }
    }
    return info;
  }

  getClosestShapeVertex(target: Position) {
    const targetXy = this.project(target);
    let current: {
      vertex:
        | PolygonVertexFeature
        | PathVertexFeature
        | BooleanVertexFeature<"rectangle">
        | BooleanVertexFeature<"polygon">;
      xyDistance: number;
    } | null = null;
    for (const group of [...this.props.data.shapes.values()]) {
      if (group.type === "polygon") {
        for (const vertex of group.exterior) {
          const positionXy = this.project(vertex.geometry.coordinates);
          const xyDistance = squaredDistance(targetXy, positionXy);
          if (!current || xyDistance < current.xyDistance) {
            current = { vertex, xyDistance };
          }
        }
        for (const hole of group.holes) {
          if (hole) {
            for (const vertex of hole) {
              const positionXy = this.project(vertex.geometry.coordinates);
              const xyDistance = squaredDistance(targetXy, positionXy);
              if (!current || xyDistance < current.xyDistance) {
                current = { vertex, xyDistance };
              }
            }
          }
        }
      } else if (group.type === "path") {
        for (const vertex of group.vertices) {
          const positionXy = this.project(vertex.geometry.coordinates);
          const xyDistance = squaredDistance(targetXy, positionXy);
          if (!current || xyDistance < current.xyDistance) {
            current = { vertex, xyDistance };
          }
        }
      } else if (group.type === "rectangle") {
        for (const hole of group.holes) {
          if (hole) {
            for (const vertex of hole) {
              const positionXy = this.project(vertex.geometry.coordinates);
              const xyDistance = squaredDistance(targetXy, positionXy);
              if (!current || xyDistance < current.xyDistance) {
                current = { vertex, xyDistance };
              }
            }
          }
        }
      }
    }
    return current;
  }

  getClosestMidpoint(target: Position): MidpointToCreateVertex | null {
    const project = this.context.viewport.project;
    const unproject = this.context.viewport.unproject;

    const targetXy = project(target);
    let current: MidpointToCreateVertex | null = null;
    for (const shape of [...this.props.data.shapes.values()]) {
      if (shape.type === "polygon") {
        for (let j = 0; j < shape.exterior.length; j++) {
          const before = shape.exterior[j];
          const after = shape.exterior[(j + 1) % shape.exterior.length];
          const midpointXy = midpoint(project(before.geometry.coordinates), project(after.geometry.coordinates));
          const xyDistance = squaredDistance(targetXy, midpointXy);
          if (!current || xyDistance < current.xyDistance) {
            const position = unproject(midpointXy);
            current = { shapeType: "polygon", before, after, position, xyDistance };
          }
        }

        for (const ring of shape.holes) {
          for (let j = 0; j < ring.length; j++) {
            const before = ring[j];
            const after = ring[(j + 1) % ring.length];
            const midpointXy = midpoint(project(before.geometry.coordinates), project(after.geometry.coordinates));
            const xyDistance = squaredDistance(targetXy, midpointXy);
            if (!current || xyDistance < current.xyDistance) {
              const position = unproject(midpointXy);
              current = { shapeType: "boolean", parentShapeType: "polygon", before, after, position, xyDistance };
            }
          }
        }
      } else if (shape.type === "path") {
        for (let j = 0; j < shape.vertices.length - 1; j++) {
          const before = shape.vertices[j];
          const after = shape.vertices[j + 1];
          const midpointXy = midpoint(project(before.geometry.coordinates), project(after.geometry.coordinates));
          const xyDistance = squaredDistance(targetXy, midpointXy);
          if (!current || xyDistance < current.xyDistance) {
            const position = unproject(midpointXy);
            current = { shapeType: "path", before, after, position, xyDistance };
          }
        }
      } else if (shape.type === "rectangle") {
        for (const ringNoLoop of shape.holes) {
          const ring = [...ringNoLoop, ringNoLoop[0]];
          for (let j = 0; j < ring.length - 1; j++) {
            const before = ring[j];
            const after = ring[j + 1];
            const midpointXy = midpoint(project(before.geometry.coordinates), project(after.geometry.coordinates));
            const xyDistance = squaredDistance(targetXy, midpointXy);
            if (!current || xyDistance < current.xyDistance) {
              const position = unproject(midpointXy);
              current = { shapeType: "boolean", parentShapeType: "rectangle", before, after, position, xyDistance };
            }
          }
        }
      }
    }
    return current;
  }

  renderLayers() {
    return [
      new GeoJsonLayer({
        id: "polygon-layer",
        data: toFeatureCollection(this.props.data),
        pickable: true,
        stroked: false,
        getFillColor: FILL_COLOR,
        getLineColor: LINE_COLOR,
        getPointRadius: 2,
      }),
    ];
  }
}

const FILL_COLOR: Color = [160, 160, 180, 200];
const LINE_COLOR: Color = [160, 160, 180, 200];

const squaredDistance = (a: Position, b: Position) => {
  const dx = a[0] - b[0];
  const dy = a[1] - b[1];
  return dx * dx + dy * dy;
};

const midpoint = (a: Position, b: Position) => {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
};
