import { Feature, Point } from "geojson";
import { Corner } from "./rectangle";

export const SHAPE_TYPE = {
  polygon: "polygon",
  rectangle: "rectangle",
  path: "path",
  boolean: "boolean",
} as const;
export type ShapeType = (typeof SHAPE_TYPE)[keyof typeof SHAPE_TYPE];
export const TENTATIVE_SHAPE_TYPE = {
  ...SHAPE_TYPE,
  none: "none",
} as const;
export type TentativeShapeType = (typeof TENTATIVE_SHAPE_TYPE)[keyof typeof TENTATIVE_SHAPE_TYPE];

export type MapPolygon = {
  type: "polygon";
  exterior: PolygonVertexFeature[];
  holes: BooleanVertexFeature<"polygon">[][];
};
export type MapRectangle = {
  type: "rectangle";
  exterior: RectangleVertexFeature[];
  holes: BooleanVertexFeature<"rectangle">[][];
};
export type MapPath = {
  type: "path";
  vertices: PathVertexFeature[];
};
export type MapShape = MapPolygon | MapRectangle | MapPath;

export type PolygonVertexFeature = Feature<
  Point,
  { type: "shape"; shapeType: "polygon"; featureId: string; vertexId: string; vertexIndex: number }
>;
export type RectangleVertexFeature = Feature<
  Point,
  // we use horizontalNeighborId and verticalNeighborId since the corner can change if the rectangle is flipped, but shareLngId and shareLatId stay the same
  {
    type: "shape";
    shapeType: "rectangle";
    featureId: string;
    vertexId: string;
    horizontalNeighborId: string;
    verticalNeighborId: string;
  }
>;
export type PathVertexFeature = Feature<
  Point,
  { type: "shape"; shapeType: "path"; featureId: string; vertexId: string }
>;
// RectangleBoolean more accurate
export type BooleanVertexFeature<T extends "polygon" | "rectangle"> = Feature<
  Point,
  {
    type: "shape";
    shapeType: "boolean";
    parentShapeType: T;
    featureId: string;
    holeIndex: number;
    holeId: string;
    vertexId: string;
    parentShapeId: string;
    vertexIndex: number;
  }
>;

export type TentativeSequenceVertexFeature = Feature<
  Point,
  {
    type: "tentative";
    sequenceIndex: number;
    clientId: string;
    vertexId: string;
  }
>;
export type TentativeFinalVertexFeature = Feature<
  Point,
  {
    type: "tentative";
    clientId: string;
  }
>;

export type TentativePolygonVertexFeature = Feature<
  Point,
  TentativeSequenceVertexFeature["properties"] & {
    shapeType: "polygon";
  }
>;
export type TentativePolygon = {
  type: "polygon";
  vertices: Record<string, TentativePolygonVertexFeature>;
  final?: TentativeFinalVertexFeature;
};

export type TentativeRectangleVertexFeature = Feature<
  Point,
  {
    type: "tentative";
    shapeType: "rectangle";
    horizontalNeighborId: string;
    verticalNeighborId: string;
    vertexId: string;
    corner: Corner;
  }
>;
export type TentativeRectangle = {
  type: "rectangle";
  initial: TentativeRectangleVertexFeature;
  derived: Record<string, TentativeRectangleVertexFeature>;
  final?: TentativeRectangleVertexFeature;
};

export type TentativePathVertexFeature = Feature<
  Point,
  {
    type: "tentative";
    shapeType: "path";
    vertexId: string;
    sequenceIndex: number;
  }
>;
export type TentativePath = {
  type: "path";
  vertices: TentativePathVertexFeature[];
  final?: TentativePathVertexFeature;
};

export type TentativeBooleanVertexFeature = Feature<
  Point,
  TentativeSequenceVertexFeature["properties"] & { shapeType: "boolean" }
>;
export type TentativeBoolean = {
  type: "boolean";
  parentShapeId: string;
  vertices: Record<string, TentativeBooleanVertexFeature>;
  final?: TentativeFinalVertexFeature;
};

export type TentativeShape = TentativePolygon | TentativeRectangle | TentativePath | TentativeBoolean;
