import * as turf from "@turf/turf";
import { Position } from "geojson";
import invariant from "tiny-invariant";

import { vertexLib } from "./lib/vertex";
import { positionLib } from "./lib/position";
import { isProperty, State, StateNode, idLib } from "@larki/mp";
import {
  MapPath,
  MapPolygon,
  MapRectangle,
  MapShape,
  PathVertexFeature,
  PolygonVertexFeature,
  RectangleVertexFeature,
  SHAPE_TYPE,
  TENTATIVE_SHAPE_TYPE,
  TentativeFinalVertexFeature,
  TentativePathVertexFeature,
  TentativePolygonVertexFeature,
  TentativeRectangleVertexFeature,
  TentativeShape,
  TentativeShapeType,
  TentativeBooleanVertexFeature,
  BooleanVertexFeature,
} from "./lib/shape";
import { arrayLib } from "./lib/array";
import { Corner, PositionIdPair, rectangleLib } from "./lib/rectangle";

export type Tentative<T extends TentativeShape> = {
  sequenceIds: string[];
  shape?: T;
};
export type WorldStateT = {
  cursors: Map<string, Position>;
  shapes: Map<string, MapShape>;
  tentativeShapes: Map<string, Tentative<TentativeShape>>;
};
export class WorldState {
  private state: State;
  private fulfilledPredictions: Set<string>;

  constructor(state: State, fulfilledPredictions: Set<string>) {
    this.state = state;
    this.fulfilledPredictions = fulfilledPredictions;
  }

  interpolate(interpolatedPositions: Map<string, Position>) {
    const interpolated = this.state.clone();
    for (const [clientId, interpolatedPosition] of interpolatedPositions) {
      const path = ["clients", clientId, "position"];
      interpolated.set(path, {
        value: positionLib.toString(interpolatedPosition),
        t: this.state.get(path)?.t ?? Date.now(),
      });
    }
    return new WorldState(interpolated, this.fulfilledPredictions);
  }

  getState() {
    return this.state;
  }

  getFulfilledPredictions() {
    return this.fulfilledPredictions;
  }

  getCursors(excludeId?: string | null): WorldStateT["cursors"] {
    const result = new Map();
    const clients = this.state.getNode(["clients"]);
    if (!clients) return result;
    for (const [clientId, node] of clients) {
      invariant(!isProperty(node));
      const position = node.get("position");
      if (!position) continue;
      invariant(isProperty(position));
      if (!position.value) continue;
      if (excludeId && clientId === excludeId) continue;
      result.set(clientId, positionLib.fromString(position.value));
    }
    return result;
  }

  getShapes(): WorldStateT["shapes"] {
    // first establish which clients are dragging onto which vertices
    const clientsNode = this.state.getNode(["clients"]);
    const vertexIdToDraggingClientId = new Map<string, string>();
    const clientIdToPosition = new Map<string, Position>();
    if (clientsNode) {
      [...clientsNode].forEach(([clientId, child]) => {
        invariant(!isProperty(child));
        const positionProp = child.get("position");
        if (positionProp) {
          invariant(isProperty(positionProp));
          if (positionProp.value) {
            const position = positionLib.fromString(positionProp.value);
            clientIdToPosition.set(clientId, position);
          }
        }
        const draggingProp = child.get("draggingShapeVertexId");
        if (draggingProp) {
          invariant(isProperty(draggingProp));
          if (draggingProp.value && draggingProp.value !== "NONE") {
            vertexIdToDraggingClientId.set(draggingProp.value, clientId);
          }
        }
      });
    }

    // now build the shapes

    const parsePolygon = (polyNode: StateNode, featureId: string): MapPolygon => {
      const exteriorNode = polyNode.get("exterior");
      invariant(exteriorNode);
      invariant(!isProperty(exteriorNode));

      invariant(State.isValidListNode(exteriorNode));
      const exterior = State.toList(exteriorNode)!
        .map(([vertexId, vertex], vertexIndex) => {
          const properties: PolygonVertexFeature["properties"] = {
            type: "shape",
            shapeType: SHAPE_TYPE.polygon,
            featureId,
            vertexId,
            vertexIndex,
          };
          if (!vertex.value) return null;
          let position = positionLib.fromString(vertex.value);
          const cId = vertexIdToDraggingClientId.get(vertexId);
          if (cId) {
            const cPos = clientIdToPosition.get(cId);
            if (cPos) {
              position = cPos;
            }
          }
          return turf.point(position, properties);
        })
        .filter(arrayLib.filterNonNull);

      const holesNode = polyNode.get("holes");
      let holes: BooleanVertexFeature<"polygon">[][] = [];
      if (holesNode) {
        invariant(!isProperty(holesNode));
        holes = [...holesNode].map(([holeId, holeNode], holeIndex) => {
          invariant(!isProperty(holeNode));
          invariant(State.isValidListNode(holeNode));
          return State.toList(holeNode)!
            .map(([vertexId, vertex], vertexIndex): BooleanVertexFeature<"polygon"> | null => {
              invariant(isProperty(vertex));
              if (!vertex.value) return null;
              let position = positionLib.fromString(vertex.value);
              const cId = vertexIdToDraggingClientId.get(vertexId);
              if (cId) {
                const cPos = clientIdToPosition.get(cId);
                if (cPos) {
                  position = cPos;
                }
              }
              return turf.point(position, {
                type: "shape",
                vertexId,
                holeId,
                featureId,
                shapeType: SHAPE_TYPE.boolean,
                parentShapeType: "polygon",
                parentShapeId: featureId,
                holeIndex,
                vertexIndex,
              });
            })
            .filter(arrayLib.filterNonNull);
        });
      }

      return { type: "polygon", exterior, holes };
    };

    const polygonsNode = this.state.getNode(["polygons"]);

    const polygons = new Map(
      [...(polygonsNode ?? [])].map(([fId, polyNode]): [string, MapPolygon] => {
        invariant(!isProperty(polyNode));
        return [fId, parsePolygon(polyNode, fId)];
      })
    );

    const rectanglesNode = this.state.getNode(["rectangles"]);
    const rectangles = new Map(
      [...(rectanglesNode ?? [])]
        .map(([featureId, rectangle]): [string, MapRectangle] | null => {
          invariant(!isProperty(rectangle));
          const exteriorNode = rectangle.get("exterior");
          if (!exteriorNode) return null;
          invariant(!isProperty(exteriorNode));

          // prior to interpolation
          const initialCornerToVertex = {} as { [key in Corner]: PositionIdPair };
          for (const [vertexId, vertexProp] of exteriorNode) {
            invariant(!isProperty(vertexProp));
            const cornerProp = vertexProp.get("corner");
            invariant(cornerProp && isProperty(cornerProp));
            if (!cornerProp.value) continue;
            const corner = rectangleLib.cornerFromString(cornerProp.value);

            const positionProp = vertexProp.get("position");
            invariant(positionProp && isProperty(positionProp));
            if (!positionProp.value) continue;
            const position = positionLib.fromString(positionProp.value);

            initialCornerToVertex[corner] = { position, id: vertexId };
          }
          if (Object.keys(initialCornerToVertex).length < 4) return null;

          // after interpolation
          const interpolatedCornerToVertex = { ...initialCornerToVertex };
          for (const [cornerStr, vertex] of Object.entries(initialCornerToVertex)) {
            const corner = parseInt(cornerStr, 10) as Corner;
            const draggingClientId = vertexIdToDraggingClientId.get(vertex.id);
            if (draggingClientId) {
              const position = clientIdToPosition.get(draggingClientId);
              if (position) {
                const [adjCornerH, adjCornerV] = rectangleLib.getAdjacentCorners(corner);
                const adjVertexH = initialCornerToVertex[adjCornerH];
                const adjVertexV = initialCornerToVertex[adjCornerV];
                interpolatedCornerToVertex[corner].position = position;
                interpolatedCornerToVertex[adjCornerH].position = [adjVertexH.position[0], position[1]];
                interpolatedCornerToVertex[adjCornerV].position = [position[0], adjVertexV.position[1]];
              }
            }
          }

          const exterior = [Corner.ne, Corner.nw, Corner.sw, Corner.se].map((corner): RectangleVertexFeature => {
            const v = interpolatedCornerToVertex[corner];
            const adj = rectangleLib.getAdjacentCorners(corner);
            return turf.point(v.position, {
              type: "shape",
              shapeType: SHAPE_TYPE.rectangle,
              featureId,
              vertexId: v.id,
              horizontalNeighborId: interpolatedCornerToVertex[adj[0]].id,
              verticalNeighborId: interpolatedCornerToVertex[adj[1]].id,
            });
          }); // in CCW order

          const holesNode = rectangle.get("holes");
          let holes: BooleanVertexFeature<"rectangle">[][] = [];
          if (holesNode) {
            invariant(!isProperty(holesNode));
            holes = [...holesNode].map(([holeId, holeNode], holeIndex) => {
              invariant(!isProperty(holeNode));
              invariant(State.isValidListNode(holeNode));
              return State.toList(holeNode)!
                .map(([vertexId, vertex], vertexIndex): BooleanVertexFeature<"rectangle"> | null => {
                  invariant(isProperty(vertex));
                  if (!vertex.value) return null;
                  let position = positionLib.fromString(vertex.value);
                  const cId = vertexIdToDraggingClientId.get(vertexId);
                  if (cId) {
                    const cPos = clientIdToPosition.get(cId);
                    if (cPos) {
                      position = cPos;
                    }
                  }
                  return turf.point(position, {
                    type: "shape",
                    vertexId,
                    holeId,
                    featureId,
                    shapeType: SHAPE_TYPE.boolean,
                    parentShapeType: "rectangle",
                    parentShapeId: featureId,
                    holeIndex,
                    vertexIndex,
                  });
                })
                .filter(arrayLib.filterNonNull);
            });
          }

          return [featureId, { type: "rectangle", exterior, holes }];
        })
        .filter(arrayLib.filterNonNull)
    );

    const pathsNode = this.state.getNode(["paths"]);
    const paths = new Map(
      [...(pathsNode ?? [])].map(([featureId, pathNode]) => {
        invariant(!isProperty(pathNode));
        invariant(State.isValidListNode(pathNode));

        const vertices = State.toList(pathNode)!
          .map(([vertexId, vertex]) => {
            const properties: PathVertexFeature["properties"] = {
              type: "shape",
              shapeType: SHAPE_TYPE.path,
              featureId,
              vertexId,
            };
            if (!vertex.value) return null;
            let position = positionLib.fromString(vertex.value);
            const cId = vertexIdToDraggingClientId.get(vertexId);
            if (cId) {
              const cPos = clientIdToPosition.get(cId);
              if (cPos) {
                position = cPos;
              }
            }
            return turf.point(position, properties);
          })
          .filter(arrayLib.filterNonNull);

        return [featureId, { type: "path", vertices } satisfies MapPath];
      })
    );

    const shapes: [string, MapShape][] = [...polygons, ...rectangles, ...paths];
    return new Map(shapes);
  }

  getTentativeSequences(): WorldStateT["tentativeShapes"] {
    const result = new Map();
    const clients = this.state.getNode(["clients"]);
    if (!clients) return result;
    return new Map(
      [...clients]
        .map(([clientId, child]): [string, Tentative<TentativeShape>] | null => {
          invariant(!isProperty(child));
          const tentativeSequence = child.get("tentativeSequence");

          if (!tentativeSequence) return null;
          invariant(!isProperty(tentativeSequence));
          const tentativeTypeProp = child.get("tentativeType");

          const sequenceIds = [...tentativeSequence]
            .filter(([, vertexProp]) => {
              invariant(isProperty(vertexProp));
              return vertexProp.value !== null;
            })
            .map(([vertexId]) => vertexId);

          if (tentativeTypeProp) {
            invariant(isProperty(tentativeTypeProp));

            if (tentativeTypeProp.value) {
              const tentativeType = tentativeTypeProp.value as TentativeShapeType;

              if (tentativeType === TENTATIVE_SHAPE_TYPE.polygon) {
                const vertices: Record<string, TentativePolygonVertexFeature> = {};
                [...tentativeSequence].forEach(([vertexId, vertexProp]) => {
                  invariant(isProperty(vertexProp));
                  if (!vertexProp.value) return;
                  const { position, positionIndices } = vertexLib.fromString(vertexProp.value);
                  vertices[vertexId] = turf.point(position, {
                    type: "tentative",
                    shapeType: tentativeType,
                    sequenceIndex: positionIndices[0],
                    clientId,
                    vertexId,
                  });
                });

                let final: TentativeFinalVertexFeature | undefined;
                const positionProp = child.get("position");
                if (positionProp) {
                  invariant(isProperty(positionProp));
                  if (positionProp.value) {
                    const position = positionLib.fromString(positionProp.value);
                    final = turf.point(position, {
                      type: "tentative",
                      clientId,
                    });
                  }
                }

                return [clientId, { sequenceIds, shape: { type: "polygon", vertices, final } }];
              } else if (tentativeType === TENTATIVE_SHAPE_TYPE.boolean) {
                // TODO: this is effectively the same logic as polygon, should refactor
                const vertices: Record<string, TentativeBooleanVertexFeature> = {};
                [...tentativeSequence].forEach(([vertexId, vertexProp]) => {
                  invariant(isProperty(vertexProp));
                  if (!vertexProp.value) return;
                  const { position, positionIndices } = vertexLib.fromString(vertexProp.value);
                  vertices[vertexId] = turf.point(position, {
                    type: "tentative",
                    shapeType: tentativeType,
                    sequenceIndex: positionIndices[0],
                    clientId,
                    vertexId,
                  });
                });

                let final: TentativeFinalVertexFeature | undefined;
                const positionProp = child.get("position");
                if (positionProp) {
                  invariant(isProperty(positionProp));
                  if (positionProp.value) {
                    const position = positionLib.fromString(positionProp.value);
                    final = turf.point(position, {
                      type: "tentative",
                      clientId,
                    });
                  }
                }

                const parentShapeId = child.get("tentativeParentShapeId");
                invariant(parentShapeId && isProperty(parentShapeId) && parentShapeId.value);
                return [
                  clientId,
                  { sequenceIds, shape: { type: "boolean", vertices, final, parentShapeId: parentShapeId.value } },
                ];
              } else if (tentativeType === TENTATIVE_SHAPE_TYPE.rectangle) {
                let final: PositionIdPair | undefined;
                const positionProp = child.get("position");
                if (!positionProp) return null;
                invariant(isProperty(positionProp));
                if (positionProp.value) {
                  const position = positionLib.fromString(positionProp.value);
                  // TODO: making a new ID every time, should use the client ID instead, maybe?
                  final = { position, id: idLib.shortId() };
                }
                if (!final) return null;

                let initial: PositionIdPair | undefined;
                for (const [vertexId, vertexProp] of tentativeSequence) {
                  invariant(isProperty(vertexProp));
                  if (!vertexProp.value) continue;
                  const {
                    position,
                    positionIndices: [i],
                  } = vertexLib.fromString(vertexProp.value);
                  if (i === 0) {
                    initial = { position, id: vertexId };
                  }
                }
                if (!initial) return null;
                const finalEastEdge = final.position[0] > initial.position[0];
                const finalNorthEdge = final.position[1] > initial.position[1];

                let [left, right] = [initial, final];
                if (!finalEastEdge) {
                  [left, right] = [final, initial];
                }
                invariant(left.position[0] <= right.position[0]);

                let [sw, ne, nw, se]: PositionIdPair[] = [];
                if (left.position[1] <= right.position[1]) {
                  // left is south-west, right is north-east
                  sw = left;
                  ne = right;
                  nw = { id: idLib.shortId(), position: [sw.position[0], ne.position[1]] };
                  se = { id: idLib.shortId(), position: [ne.position[0], sw.position[1]] };
                } else {
                  // left is north-west, right is south-east
                  nw = left;
                  se = right;
                  sw = { id: idLib.shortId(), position: [nw.position[0], se.position[1]] };
                  ne = { id: idLib.shortId(), position: [se.position[0], nw.position[1]] };
                }
                invariant(sw && ne && nw && se);
                const vertices = { [Corner.sw]: sw, [Corner.ne]: ne, [Corner.nw]: nw, [Corner.se]: se };
                const finalCorner = rectangleLib.cornerFromEdges(finalNorthEdge, finalEastEdge);
                const initialCorner = rectangleLib.getOppositeCorner(finalCorner);
                const finalAdjacentCorners = rectangleLib.getAdjacentCorners(finalCorner);

                const _getVertexIds = (corners: [Corner, Corner]) => {
                  return corners.map((corner) => vertices[corner].id) as [string, string];
                };

                return [
                  clientId,
                  {
                    sequenceIds,
                    shape: {
                      type: "rectangle",
                      initial: tentativeRectangleVertex(
                        initial.id,
                        initial.position,
                        _getVertexIds(rectangleLib.getAdjacentCorners(initialCorner)),
                        initialCorner
                      ),
                      final: tentativeRectangleVertex(
                        final.id,
                        final.position,
                        _getVertexIds(finalAdjacentCorners),
                        finalCorner
                      ),
                      derived: Object.fromEntries(
                        finalAdjacentCorners.map((corner) => {
                          const v = vertices[corner];
                          return [
                            v.id,
                            tentativeRectangleVertex(
                              v.id,
                              v.position,
                              _getVertexIds(rectangleLib.getAdjacentCorners(corner)),
                              corner
                            ),
                          ];
                        })
                      ),
                    },
                  },
                ];
              } else if (tentativeType === TENTATIVE_SHAPE_TYPE.path) {
                const vertices = [...tentativeSequence]
                  .map(([id, vertexProp]): TentativePathVertexFeature | null => {
                    invariant(isProperty(vertexProp));
                    if (!vertexProp.value) return null;
                    const {
                      position,
                      positionIndices: [i],
                    } = vertexLib.fromString(vertexProp.value);
                    return tentativePathVertex(id, position, i);
                  })
                  .filter(arrayLib.filterNonNull);

                let final: TentativePathVertexFeature | undefined;
                const positionProp = child.get("position");
                if (!positionProp) return null;
                invariant(isProperty(positionProp));
                if (positionProp.value) {
                  const position = positionLib.fromString(positionProp.value);
                  // TODO: making a new ID every time
                  final = tentativePathVertex(idLib.shortId(), position, 0);
                }
                if (!final) return null;
                return [clientId, { sequenceIds, shape: { type: "path", vertices, final } }];
              }
            }
          }

          return null;
        })
        .filter(arrayLib.filterNonNull)
    );
  }

  asFlatString() {
    return this.state.asFlatString();
  }
}

const tentativeRectangleVertex = (
  vertexId: string,
  position: Position,
  neighborIds: [string, string],
  corner: Corner
): TentativeRectangleVertexFeature =>
  turf.point(position, {
    type: "tentative",
    shapeType: "rectangle",
    horizontalNeighborId: neighborIds[0],
    verticalNeighborId: neighborIds[1],
    vertexId,
    corner,
  });

const tentativePathVertex = (vertexId: string, position: Position, sequenceIndex: number): TentativePathVertexFeature =>
  turf.point(position, { type: "tentative", shapeType: "path", vertexId, sequenceIndex });
