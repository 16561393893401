import proj4 from 'proj4';
import { Spline } from './spline';
import { WorldState } from './worldState';
import { Position } from 'geojson';

type AnimationState = 'stopped' | 'idle' | 'animating';
type Animation<T> = {
  from: number[];
  to: number[];
  start: number;
  duration: number;
  extra: T;
};
export type PositionCallback<T> = (point: number[], extra: T) => void;

class PointInterpolator<T> {
  state: AnimationState = 'idle';
  queue: Animation<T>[] = [];
  timestamp = performance.now();
  lastRequestId = 0;
  timeoutId?: NodeJS.Timeout;
  prevPoint?: number[];
  spline = new Spline();
  cb: PositionCallback<T>;

  constructor(cb: PositionCallback<T>) {
    this.cb = cb;
  }

  addPoint(point: number[], extra: T) {
    clearTimeout(this.timeoutId);
    const now = performance.now();
    const duration = Math.min(
      now - this.timestamp,
      PointInterpolator.MAX_INTERVAL
    );
    if (!this.prevPoint) {
      this.spline.clear();
      this.prevPoint = point;
      this.spline.addPoint(point);
      this.cb(point, extra);
      this.state = 'stopped';
      return;
    }
    if (this.state === 'stopped') {
      this.spline.clear();
      this.spline.addPoint(this.prevPoint);
      this.spline.addPoint(this.prevPoint);
      this.spline.addPoint(point);
      this.state = 'idle';
    } else {
      this.spline.addPoint(point);
    }
    const animation: Animation<T> = {
      start: this.spline.points.length - 3,
      from: this.prevPoint,
      to: point,
      duration,
      extra,
    };
    this.prevPoint = point;
    this.timestamp = now;
    switch (this.state) {
      case 'idle': {
        this.state = 'animating';
        this.animateNext(animation);
        break;
      }
      case 'animating': {
        this.queue.push(animation);
        break;
      }
    }
  }

  animateNext = (animation: Animation<T>) => {
    const start = performance.now();
    const loop = () => {
      const t = (performance.now() - start) / animation.duration;
      if (t <= 1 && this.spline.points.length > 0) {
        try {
          this.cb(
            this.spline.getSplinePoint(t + animation.start),
            animation.extra
          );
        } catch (e) {
          console.warn(e);
        }
        this.lastRequestId = requestAnimationFrame(loop);
        return;
      }
      const next = this.queue.shift();
      if (next) {
        this.state = 'animating';
        this.animateNext(next);
      } else {
        this.state = 'idle';
        this.timeoutId = setTimeout(() => {
          this.state = 'stopped';
        }, PointInterpolator.MAX_INTERVAL);
      }
    };
    loop();
  };

  static MAX_INTERVAL = 300;

  dispose = () => {
    clearTimeout(this.timeoutId);
  };
}

const PROJECTION = proj4('WGS84', 'EPSG:3857');
// const ORIGIN = PROJECTION.forward(INITIAL_MAP_OPTIONS.CENTER);

export class WorldStateInterpolator {
  private interpolators = new Map<
    string,
    PointInterpolator<{ worldState: WorldState }>
  >();
  interpolatedPositions = new Map<string, Position>();
  private interpolatedWorldState: WorldState | null = null;

  private createInterpolator(clientId: string) {
    return new PointInterpolator<{ worldState: WorldState }>(
      (coord, { worldState }) => {
        const position = PROJECTION.inverse(coord);

        this.interpolatedPositions.set(clientId, position);

        this.interpolatedWorldState = worldState.interpolate(
          this.interpolatedPositions
        );
      }
    );
  }

  addState(worldState: WorldState) {
    for (const [clientId, position] of worldState.getCursors()) {
      if (!this.interpolators.has(clientId)) {
        this.interpolators.set(clientId, this.createInterpolator(clientId));
      }
      const coord = PROJECTION.forward(position);
      this.interpolators.get(clientId)?.addPoint(coord, { worldState });
    }
    for (const [clientId] of this.interpolatedPositions) {
      if (!worldState.getCursors().has(clientId)) {
        this.interpolatedPositions.delete(clientId);
      }
    }
  }

  getInterpolatedWorldState() {
    return this.interpolatedWorldState;
  }
}
